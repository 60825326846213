import React, { useRef, useState } from 'react'
import SearchInput from '../../../libs/reuse/components/SearchInput';
import { createSearchParams, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useModals } from '../../../providers/ModalsProvider';

function Search() {
    const inputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const { t } = useTranslation("header");
    const { closeHeaderModal } = useModals();

    const [searchInput, setSearchInput] = useState("");

    // Function to handle search navigation
    const handleSearch = () => {
        if (searchInput.trim()) {
            // Navigate to events page with search query parameter
            navigate({
                pathname: "/events",
                search: createSearchParams({
                    search: searchInput.trim()
                }).toString()
            });
            setSearchInput("");
            closeHeaderModal();
            if (inputRef.current) inputRef.current.blur();
        }
    };

    return (
        <SearchInput
            defaultText={t("search")}
            value={searchInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchInput(e.target.value)}
            onClick={handleSearch}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === "Enter") {
                    handleSearch();
                }
            }}
            ref={inputRef}
        />
    )
}

export default Search