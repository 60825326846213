import React, { MouseEvent } from "react";
import styled, { css } from "styled-components";
import Icon from "./Icon";
import { Heading4 } from "./texts/textStyles";

// Define the props interface
interface ButtonProps {
    text?: string; // Optional text to display on the button
    iconName?: string; // Optional icon name for the button
    iconSize?: string; // Optional size for the icon
    color?: string; // Optional color for the button
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void; // Optional click handler
    padding?: string; // Optional padding for the button
}

const Button: React.FC<ButtonProps> = ({
    text,
    iconName,
    iconSize,
    color,
    onClick,
    padding,
}) => {
    return (
        <ButtonWrapper color={color} onClick={onClick} $padding={padding}>
            {iconName ? <Icon name={iconName} size={iconSize} /> : null}
            {text && <Heading4>{text}</Heading4>}
        </ButtonWrapper>
    );
};

// Styled component for the button wrapper
export const ButtonWrapper = styled.button<{ $padding?: string; color?: string }>`
  font: inherit;
  color: inherit;
  fill: inherit;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  border-radius: 16px;
  padding: ${({ $padding }) => $padding || "12px 25px"};
  background-color: transparent;

  ${({ color }) =>
        color &&
        css`
      color: ${color};
      fill: ${color};
    `}

  &:hover {
    filter: saturate(0.8);
  }
`;

export default Button;