import React from "react";
import styled from "styled-components";
import Events from "../../features/events/Events";
import SEO from "../../libs/reuse/components/SEO";
import { useTranslation } from "react-i18next";

function MyCalendar() {
  const { t } = useTranslation("header");

  return (<>
    <Wrapper>
      <SEO
        title={t("myCalendar")}
        url="/my-calendar"
      />
      <Events myEvents={true} />
    </Wrapper>
  </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
`;


export default MyCalendar;
