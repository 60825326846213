import React, { useCallback, useEffect, useState } from 'react'
import EventLocation from '../events/components/EventLocation';
import TextAreaInput from '../../libs/reuse/components/TextAreaInput';
import EventSelector from '../events/components/EventSelector';
import EventPerks from '../events/components/EventPerks';
import { useTranslation } from 'react-i18next';
import Input from '../../libs/reuse/components/Input';
import useDebouncedValidation from '../../libs/reuse/hooks/useDebounce';
import validator from "validator";
import styled from 'styled-components';
import ButtonContained from '../../libs/reuse/components/ButtonContained';
import Switch from '../../libs/reuse/components/Switch';
import { serverTimestamp } from 'firebase/firestore';
import { EVENT_STATUS } from '../../constants/events';
import DatePicker from '../../libs/reuse/components/DatePicker';
import ErrorForm from '../../libs/reuse/components/ErrorForm';
import Requirements from './components/Requirements';

function EventForm({ form, onSubmit, isUpdate = false, isUpdateRequest = false, isNewEvent = false }) {
    const { t } = useTranslation("events");

    const [isKidRace, setIsKidRace] = useState(form.isKidRace);
    const [isParaRace, setIsParaRace] = useState(form.isParaRace);
    const [isRelayRace, setIsRelayRace] = useState(form.isRelayRace);
    const [isPinned, setIsPinned] = useState(form.isPinned);
    const [requestApproval, setRequestApproval] = useState(false);
    const [requestCancel, setRequestCancel] = useState(false);
    const [requestDelete, setRequestDelete] = useState(false);

    const validateName = useCallback((name) => {
        return validator.isEmpty(name) && t("emptyEventName")
    }, [t])
    const name = useDebouncedValidation(form.name, validateName)

    const validateLocation = useCallback((location) => {
        return ((validator.isEmpty(location.name) || !location.lat || !location.lng) && t("emptyLocation"))
    }, [t])
    const location = useDebouncedValidation(form.location, validateLocation)

    const validateLink = useCallback((link) => {
        return (validator.isEmpty(link) ||
            !validator.isURL(link, {
                protocols: ["http", "https"],
                require_tld: true,
                require_protocol: true,
            }))
            && t("emptyLink")
    }, [t])
    const link = useDebouncedValidation(form.link, validateLink)

    const description = useDebouncedValidation(form.description, () => true)

    const startDate = useDebouncedValidation(form.startDate, () => { return true })
    const endDate = useDebouncedValidation(form.endDate, () => { return true })

    const validateType = useCallback((type) => {
        // Check if the object is empty OR if all values are null
        const isEmptyType = Object.keys(type).length === 0 || Object.values(type).every(value => value === null);
        return isEmptyType && t("emptyType");
    }, [t]);
    const type = useDebouncedValidation(form.type, validateType);

    const additionalNotes = useDebouncedValidation(form.additionalNotes || "")

    // Add requirements state
    const [requirements, setRequirements] = useState({
        registration: form.requirements?.registration || false,
        fee: form.requirements?.fee || false,
        medicalCertificate: form.requirements?.medicalCertificate || false,
        insurance: form.requirements?.insurance || false,
    });

    // Load form data from sessionStorage on component mount (only for new events)
    useEffect(() => {
        if (isNewEvent) {
            const savedForm = sessionStorage.getItem("addEventForm");
            if (savedForm) {
                try {
                    const parsedForm = JSON.parse(savedForm);

                    // Handle text fields
                    name.setInput(parsedForm.name || "");
                    description.setInput(parsedForm.description || "");
                    link.setInput(parsedForm.link || "");

                    // Handle location object
                    if (parsedForm.location) {
                        location.setInput({
                            name: parsedForm.location.name || "",
                            lat: parsedForm.location.lat || null,
                            lng: parsedForm.location.lng || null
                        });
                    } else {
                        location.setInput({ name: "", lat: null, lng: null });
                    }

                    // Handle dates - convert string dates back to Date objects
                    if (parsedForm.startDate) {
                        startDate.setInput(new Date(parsedForm.startDate));
                    }

                    if (parsedForm.endDate) {
                        endDate.setInput(new Date(parsedForm.endDate));
                    }

                    // Handle other fields
                    type.setInput(parsedForm.type || {});
                    setIsKidRace(parsedForm.isKidRace || false);
                    setIsParaRace(parsedForm.isParaRace || false);
                    setIsRelayRace(parsedForm.isRelayRace || false);
                    setIsPinned(parsedForm.isPinned || false);

                    // Load requirements
                    if (parsedForm.requirements) {
                        setRequirements(parsedForm.requirements);
                    }
                } catch (error) {
                    console.error("Error parsing saved form data:", error);
                    // Initialize with default values if parsing fails
                }
            }
        }
    }, []);

    // Save form data to sessionStorage whenever it changes (only for new events)
    useEffect(() => {
        if (isNewEvent) {
            // Check if this is the first render by looking for a flag in sessionStorage
            const isFirstRender = sessionStorage.getItem("formInitialized") === null;

            if (isFirstRender) {
                // Set the flag so we know next time it's not the first render
                sessionStorage.setItem("formInitialized", "true");
            } else {
                // Not the first render, so we can save form data
                const formData = {
                    name: name.input,
                    location: location.input,
                    description: description.input,
                    link: link.input,
                    startDate: startDate.input,
                    endDate: endDate.input,
                    type: type.input,
                    isKidRace,
                    isParaRace,
                    isRelayRace,
                    isPinned,
                    requirements: requirements,
                };
                sessionStorage.setItem("addEventForm", JSON.stringify(formData));
            }
        }
    }, [
        name.input, location.input, description.input, link.input,
        startDate.input, endDate.input, type.input,
        isKidRace, isParaRace, isRelayRace, isPinned, isNewEvent, requirements
    ]);

    // Clean up the initialization flag when component unmounts
    useEffect(() => {
        return () => {
            if (isNewEvent) {
                sessionStorage.removeItem("formInitialized");
            }
        };
    }, [isNewEvent]);

    useEffect(() => {
        if (startDate.input > endDate.input) {
            endDate.setInput(startDate.input);
        }
    }, [startDate.input]);

    useEffect(() => {
        if (startDate.input > endDate.input) {
            // Use the setter provided by the hook instead of direct assignment
            startDate.setInput(endDate.input);
        }
    }, [endDate.input]);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Manually trigger validations and update the error states
        const nameError = validateName(name.input);
        const linkError = validateLink(link.input);
        const locationError = validateLocation(location.input);
        const typeError = validateType(type.input);

        name.setError(nameError);
        link.setError(linkError);
        location.setError(locationError);
        type.setError(typeError);

        // Check for validation errors
        const hasError = !!(nameError || linkError || locationError || typeError);
        if (hasError) return;

        // Prepare form data
        const eventData = {
            startDate: startDate.input,
            endDate: endDate.input,
            name: name.input,
            location: location.input,
            description: description.input,
            link: link.input,
            type: type.input,
            isKidRace,
            isParaRace,
            isRelayRace,
            isPinned,
            ...(isUpdateRequest && {
                additionalNotes: additionalNotes.input,
                eventId: form.id,
                requestApproval,
                requestCancel,
                requestDelete,
                requestDate: serverTimestamp(),
            }),
            requirements: requirements,
        };

        onSubmit(eventData);

        // Clear session storage on form submission for new events
        if (isNewEvent) {
            sessionStorage.removeItem("addEventForm");
        }
    };

    return (
        <Wrapper onSubmit={handleSubmit}>
            <EventDates>
                <DatePicker
                    text={t("startDate")}
                    {...startDate}
                />
                <DatePicker
                    text={t("endDate")}
                    {...endDate}
                />
            </EventDates>
            <Input
                text={t("eventName")}
                placeholder={t("enterEventName")}
                {...name}
            />

            <EventLocation {...location} validateLocation={validateLocation} />
            {location.error && <ErrorForm error={location.error} />}

            <TextAreaInput
                text={t("description")}
                placeholder={t("enterDescription")}
                rows={5}
                {...description}
            />
            <Input
                text={t("eventLink")}
                placeholder={t("enterLink")}
                icon={"link"}
                {...link}
            />
            <EventSelector {...type} />
            <EventPerks isKidRace={isKidRace} setIsKidRace={setIsKidRace} isParaRace={isParaRace} setIsParaRace={setIsParaRace} isRelayRace={isRelayRace} setIsRelayRace={setIsRelayRace} isPinned={isPinned} setIsPinned={setIsPinned} />
            <Requirements
                requirements={requirements}
                setRequirements={setRequirements}
            />
            {isUpdateRequest && (<>
                <TextAreaInput
                    text={t("additionalNotes")}
                    placeholder={t("enterAdditionalNotes")}
                    rows={3}
                    {...additionalNotes}
                />
                <Actions>
                    {form.status === EVENT_STATUS.CANCELLED && <Switch text={t("requestApproval")} value={requestApproval} onChange={() => setRequestApproval((prev) => !prev)} />}
                    {form.status === EVENT_STATUS.APPROVED && <Switch text={t("requestCancel")} value={requestCancel} onChange={() => setRequestCancel((prev) => !prev)} />}
                    <Switch text={t("requestDelete")} value={requestDelete} onChange={() => setRequestDelete((prev) => !prev)} />
                </Actions>
            </>
            )}

            <ButtonContained
                text={isUpdateRequest ? t("send") : isUpdate ? t("update") : t("submit")}
                type="submit"
                iconName="save"
                color="#6689A1"
            />
        </Wrapper>
    )
}

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  `

const EventDates = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 760px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`;

export default EventForm