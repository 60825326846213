import React from "react";
import CalendarPage from "../Calendar/Calendar";
import SEO from "../../libs/reuse/components/SEO";

function Home() {
  return (
    <>
      <SEO />
      <CalendarPage />
    </>
  );
}

export default Home;
