import React, { ChangeEvent, KeyboardEvent, MouseEvent, forwardRef } from "react";
import styled from "styled-components";

import Button from "./Button";
import { Body2 } from "./texts/textStyles";
import { useMediaQuery } from "react-responsive";

// Define the props interface
interface SearchInputProps {
    defaultText?: string;
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
}

// Use React.forwardRef to properly handle the ref
const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(({
    defaultText = "",
    value,
    onChange,
    onClick,
    onKeyDown,
}, ref) => {

    const is960 = useMediaQuery({ maxWidth: 960 });
    const iconSize = is960 ? "20px" : "32px";

    return (
        <Wrapper onKeyDown={onKeyDown}>
            <InputBox
                ref={ref}
                type="text"
                placeholder={defaultText}
                value={value}
                onChange={onChange}
            />
            <Button
                iconName="search"
                iconSize={iconSize}
                color="#717579"
                onClick={onClick}
                padding={is960 ? "12px 16px" : "12px 25px"}
            />
        </Wrapper>
    );
});

// Add display name for better debugging
SearchInput.displayName = "SearchInput";

const Wrapper = styled(Body2)`
  display: flex;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #eeeeee;
  background-color: #FBFBFB;

  @media (max-width: 960px) {
    width: 100%;
    flex-direction: row-reverse;
  }
`;

const InputBox = styled.input`
  border: none;
  background-color: transparent;
  padding: 20px 20px;
  font: inherit;
  flex-grow: 1;

  /* Secondary Text */
  color: #717579;

  &:focus {
    outline: none;
  }

  @media (max-width: 960px) {
    padding: 12px 20px;
  }
`;

export default SearchInput;