import React from "react";
import { ReactSVG } from "react-svg";
import styled, { css } from "styled-components";

// Define the props interface
interface IconProps {
    name: string; // Name of the icon (required)
    color?: string; // Optional color for the icon
    size?: string; // Optional size for the icon (e.g., "24px")
    transform?: string; // Optional transform property (e.g., "rotate(45deg)")
    padding?: string; // Optional padding for the icon
}

// Styled component for the SVG icon
const StyledSVGIcon = styled<any>(ReactSVG) <{
    size?: string;
    transform?: string;
    padding?: string;
    color?: string;
}>`
  svg {
    fill: inherit;
    ${({ size }) =>
        size
            ? `
        width: ${size};
        height: ${size};
      `
            : `
        width: 24px;
        height: 24px;
      `}
    ${({ transform }) =>
        transform &&
        css`
        transform: ${transform};
      `}
    ${({ padding }) =>
        padding &&
        css`
        padding: ${padding};
      `}
    path {
      ${({ color }) =>
        color &&
        css`
          fill: ${color};
        `}
    }
  }

  div {
    display: flex;
  }
`;

// Functional component with props typed
const Icon: React.FC<IconProps> = ({ name, color, size, transform, padding }) => {
    return (
        <StyledSVGIcon
            src={`/icons/${name}.svg`}
            color={color}
            size={size}
            transform={transform}
            padding={padding}
        />
    );
};

export default Icon;