import React, { useEffect, useState } from "react";
import EventsList from "./components/EventsList";
import styled from "styled-components";
import HorizontalTabs from "../../libs/reuse/components/HorizontalTabs";
import Button from "../../libs/reuse/components/Button";
import { useLocation } from "react-router";
import { useCalendar } from "../../providers/CalendarProvider";
import { isAfter, isBefore, isEqual } from "date-fns";
import {
  Heading2
} from "../../libs/reuse/components/texts/textStyles";
import { EVENT_STATUS } from "../../constants/events";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../providers/AuthProvider";
import Loader from "../../libs/reuse/components/Loader";
import EventFilters from "./components/EventFilters";
import EventSearch from "./components/EventSearch";

function Events({ toApprove = false, myEvents = false, kids = false }) {
  const { user } = useAuth();
  const { events, loading } = useCalendar();
  const { t } = useTranslation("events");
  const locationURL = useLocation()

  const PAST_TAB = { name: t("past"), value: "past" };
  const FUTURE_TAB = { name: t("future"), value: "future" };

  const isAdminPage = locationURL?.pathname?.includes("admin")

  const [allEvents, setAllEvents] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [filterResults, setFilterResults] = useState([]);
  const [tab, setTab] = useState("future");
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    switch (tab) {
      case PAST_TAB.value:
        setAllEvents(
          events
            .filter(
              (event) =>
                (isAdminPage || (event.status === EVENT_STATUS.APPROVED || event.status === EVENT_STATUS.CANCELLED)) &&
                (toApprove ? event.status === EVENT_STATUS.APPROVAL_PENDING : true) &&
                (myEvents ? user?.events?.[event.id] : true) &&
                (kids ? event.isKidRace : true) &&
                isBefore(
                  event.startDate.setHours(0, 0, 0, 0),
                  new Date().setHours(0, 0, 0, 0)
                )
            )
            .sort((a, b) => b.startDate - a.startDate || b.endDate - a.endDate)
        );
        break;
      case FUTURE_TAB.value:
        setAllEvents(
          events
            .filter(
              (event) =>
                (isAdminPage || (event.status === EVENT_STATUS.APPROVED || event.status === EVENT_STATUS.CANCELLED)) &&
                (toApprove ? event.status === EVENT_STATUS.APPROVAL_PENDING : true) &&
                (myEvents ? user?.events?.[event.id] : true) &&
                (kids ? event.isKidRace : true) &&
                (
                  isAfter(
                    event.startDate.setHours(0, 0, 0, 0),
                    new Date().setHours(0, 0, 0, 0)
                  ) ||
                  isEqual(
                    event.startDate.setHours(0, 0, 0, 0),
                    new Date().setHours(0, 0, 0, 0)
                  ))
            )
            .sort((a, b) => a.startDate - b.startDate || a.endDate - b.endDate)
        );
        break;
      default:
        break;
    }
  }, [tab, events, PAST_TAB.value, FUTURE_TAB.value, isAdminPage, toApprove, myEvents, kids, user?.events]);

  return (
    <List>
      <Header>
        <Heading2>{!isAdminPage && t("events")}</Heading2>
        <HorizontalTabs
          tabs={[PAST_TAB, FUTURE_TAB]}
          selected={tab}
          setSelected={setTab}
        />
        <Button
          iconName="filter"
          text={t("filter")}
          onClick={() => {
            setShowFilter((prev) => !prev);
          }}
          padding={"0px 0px"}
        />
      </Header>
      <EventSearch
        events={allEvents}
        setSearchResults={setSearchResults}
      />
      <EventFilters showFilter={showFilter} events={searchResults} setFilterResults={setFilterResults} />
      {loading ?
        <Loader /> :
        <EventsList events={filterResults} />}
    </List>
  );
}

const List = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  padding: 30px 15px;

  @media (max-width: 480px) {
    padding: 15px 6px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #303134;
`;

export default Events;
