import React from 'react'
import Events from '../../features/events/Events';
import styled from 'styled-components';
import SEO from '../../libs/reuse/components/SEO';
import { useTranslation } from 'react-i18next';

function Kids() {
    const { t } = useTranslation("header");

    return (<>
        <Wrapper>
            <SEO
                title={t("forKids")}
                url="/kids"
            />
            <Events kids={true} />
        </Wrapper>
    </>
    );
}

const Wrapper = styled.div`
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 20px;
    `;

export default Kids