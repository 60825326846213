import React from 'react'
import styled from 'styled-components'
import Schedule from "../../features/Schedule/Schedule"
import { useTranslation } from 'react-i18next'
import Calendar from '../../features/calendar/Calendar'
import AddEventBanner from '../../features/events/components/AddEventBanner'
import { useCalendar } from '../../providers/CalendarProvider'
import Loader from '../../libs/reuse/components/Loader'

function CalendarPage() {
    const { t } = useTranslation("schedule")
    const {
        date,
        weekArray,
        weeks,
        filterType,
        setFilterType,
        filteredEvents,
        prevMonth,
        nextMonth,
        setToday,
        addEvent,
        getTodayEvents,
        dayIsReserved,
        loading,
        events,
    } = useCalendar();

    const calendarProps = {
        date,
        weekArray,
        weeks,
        filterType,
        setFilterType,
        filteredEvents,
        prevMonth,
        nextMonth,
        setToday,
        addEvent,
        getTodayEvents,
        dayIsReserved,
    }

    if (loading) return <Loader />

    return (
        <>
            <Wrapper>
                <Section>
                    <Calendar calendarProps={calendarProps} />
                    <Schedule
                        events={events}
                        title={t("suggestedEvents")}
                        show={{ pinned: true, upcomming: false, myEvents: false }} />
                </Section>
                <AddEventBanner />
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
flex-grow:1;
`

const Section = styled.div`
display: flex;
gap: 20px;

@media (max-width: 1200px) {
    flex-direction: column;
  }
`

export default CalendarPage