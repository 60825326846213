// src/components/SEO.tsx
import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOProps {
    title?: string;
    description?: string;
    keywords?: string;
    image?: string;
    url?: string;
    type?: string;
    twitterCard?: string;
    children?: React.ReactNode;
}

const SEO: React.FC<SEOProps> = ({
    title = "Един календар. Твоите състезания",
    description = "Твоят онлайн календар за аматьорски състезания и спортни събития по колоездене, плуване и бягане в България (и наблизо).",
    keywords = "iRace, racing events, racing platform, racing community, racing competitions",
    image = process.env.REACT_APP_OG_IMAGE || "https://irace.club/images/share-image.jpg",
    url = "https://irace.club",
    type = "website",
    twitterCard = "summary_large_image",
    children,
}) => {
    // Determine environment and base domain
    const isProd = process.env.NODE_ENV === 'production';
    const baseDomain = isProd
        ? "https://irace.club"
        : "https://localhost:3000";

    // Process the URL
    // If URL starts with '/' or is a relative path, prepend the domain
    const fullUrl = url.startsWith('http')
        ? url
        : `${baseDomain}${url.startsWith('/') ? url : `/${url}`}`;

    return (
        <Helmet>
            {/* Basic metadata */}
            <title>{title} | iRace.club</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />

            {/* Open Graph tags */}
            <meta property="og:site_name" content="iRace" />
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={fullUrl} />

            {/* Twitter Card */}
            <meta name="twitter:card" content={twitterCard} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />

            {/* Canonical URL */}
            <link rel="canonical" href={fullUrl} />

            {/* Additional custom tags */}
            {children}
        </Helmet>
    );
};

export default SEO;