import { Checkbox, FormControlLabel } from '@mui/material'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Heading5, Heading6, Heading7 } from '../../../libs/reuse/components/texts/textStyles';
import { useTranslation } from 'react-i18next';
import Switch from '../../../libs/reuse/components/Switch';
import { useAuth } from '../../../providers/AuthProvider';
import { updateUser } from '../../users/api/firebase';
import Icon from '../../../libs/reuse/components/Icon';
import CircleLoader from '../../../libs/reuse/components/CircleLoader';

function Requirements({ eventId, requirements, setRequirements, showRequirements = false, showFullfilled = false }) {
    const { user, id, updateUserDetails } = useAuth();
    const { t } = useTranslation("events");
    const [loadingStates, setLoadingStates] = useState({});

    // Define the checkbox options with their labels
    const options = [
        { id: 'registration', label: t("registration"), icon: "registration" },
        { id: 'fee', label: t("fee"), icon: "fee" },
        { id: 'medicalCertificate', label: t("medicalCertificate"), icon: "medical" },
        { id: 'insurance', label: t("insurance"), icon: "insurance" },
        { id: 'licence', label: t('licence'), icon: "licence" },
    ];

    const fullfilled = user?.events[eventId];
    const noSpecifiedRequirements = requirements === undefined || Object.keys(requirements).length === 0;
    const noRequirements = (requirements && Object.values(requirements).every(value => value === false));

    // Handle checkbox changes
    const handleCheckboxChange = (id) => (event) => {
        setRequirements({
            ...requirements,
            [id]: event.target.checked
        });
    };

    const updateRequirement = (value, option) => {
        // Set loading state for this specific option
        setLoadingStates(prev => ({ ...prev, [option.id]: true }));

        const events = { ...user.events };
        events[eventId] = { ...events[eventId], [option.id]: value };

        if (user) {
            updateUser(id, { ...user, events })
                .then(() => {
                    // Pass a callback to updateUserDetails that clears the loading state
                    updateUserDetails(() => {
                        setLoadingStates(prev => ({ ...prev, [option.id]: false }));
                    });
                })
                .catch((err) => {
                    console.log(err);
                    // Clear loading state on error
                    setLoadingStates(prev => ({ ...prev, [option.id]: false }));
                });
        }
    };

    if (noSpecifiedRequirements) {
        return null; // Don't render anything if no requirements are specified
    }

    return (
        <Wrapper>
            <Title>{t("requirements")}</Title>
            <Box>
                {showRequirements && noRequirements && <Heading7>{t("noRequirements")}</Heading7>}
                {options.map((option) => (
                    showRequirements ?
                        (!noRequirements && requirements[option.id] &&
                            <Requirement key={option.id}>
                                <RequirementText><Icon name={option.icon} size={"17px"} /><Heading7>{option.label}</Heading7></RequirementText>
                                {showFullfilled &&
                                    <RequirementSwitch>
                                        <Switch
                                            onChange={(checked) => { updateRequirement(checked, option) }}
                                            value={fullfilled?.[option.id]}
                                            height={18}
                                            width={36}
                                            handleDiameter={16}
                                            disabled={loadingStates[option.id]}
                                        />
                                        {loadingStates[option.id] &&
                                            <CircleLoader />
                                        }
                                    </RequirementSwitch>}
                            </Requirement>
                        ) :
                        <FormControlLabel
                            key={option.id}
                            control={
                                <Checkbox
                                    checked={requirements[option.id]}
                                    onChange={handleCheckboxChange(option.id)}
                                    sx={{
                                        color: "#6689A1",
                                        "&.Mui-checked": { color: "#6689A1" }
                                    }}
                                />
                            }
                            label={<Heading5>{option.label}</Heading5>}
                        />
                ))}
            </Box>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
`;

const Title = styled(Heading6)`
  text-transform: uppercase;
  /* Secondary Text */
  color: #717579;
`;

const Box = styled(Heading5)`
  border: 1px solid #d7d7d7;
  border-radius: 14px;
  padding: 7px 22px;
  box-sizing: border-box;
  display: flex;
  column-gap: 30px;
  row-gap: 5px;
  flex-wrap: wrap;
  width: 100%;
  color: #303134;
  fill: #717579;
  align-items: center;

  &:focus {
    border: 1px solid black;
  }

@media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
}
`;

const Requirement = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    
    @media (max-width: 480px) {
    flex-direction: row;
    gap: 15px;
}
`

const RequirementText = styled.div`
    display: flex;
    gap: 5px;
`

const RequirementSwitch = styled.div`
    align-self: center;
    position: relative;
    display: flex;
`

export default Requirements