import React from "react";
import styled from "styled-components";
import Events from "../../features/events/Events";
import AddEventBanner from "../../features/events/components/AddEventBanner";
import SEO from "../../libs/reuse/components/SEO";
import { useTranslation } from "react-i18next";

function EventsPage({ toApprove }) {
    const { t } = useTranslation("events");
    return (
        <Wrapper>
            <SEO
                title={t("events")}
                url="/events"
            />
            <Events toApprove={toApprove} />
            <AddEventBanner />
        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
`;

export default EventsPage;
